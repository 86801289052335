<template>
  <div class="slide">
    <!-- 普通样式 -->
    <div v-if="type == 'ordinary'" class="ordinary">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <img src="../../assets/矩形.png" alt="">
        <img class="quan" src="../../assets/quan.png" alt="">
        <div class="disease">{{ item.disease }}</div>
        <div class="title">{{ item.title }}</div>
        <div class="content" :style="{ background: item.defaultBga }">
        </div>
      </div>
    </div>

    <div v-if="type == 'ordin'" class="ordinary">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <img src="../../assets/矩形.png" alt="">
        <img class="quan" src="https://image.giantgocloud.com/www//ImageMapping/image/20230302/95CC8B12FE0840959D9CDEE5AC3F467B.png" alt="" v-if="item.CaseValue <= 0.45" style="margin:-162px auto 65px">
        <img class="quan" src="https://image.giantgocloud.com/www//ImageMapping/image/20230302/59A6B0C2FB224054B38887DA804BF2E9.png" alt="" v-else-if="0.45 < item.CaseValue > 0.9" style="margin:-162px auto 65px">
        <img class="quan" src="https://image.giantgocloud.com/www//ImageMapping/image/20230302/88C3BF4BFF9F44CA982AB6B815EA6D93.png" alt="" v-else-if="item.CaseValue > 0.9" style="margin:-162px auto 65px">
        <div class="disease" style="color:#000">{{ item.ShowOrgan }}</div>
        <div class="title" v-if="item.CaseValue <= 0.45">严重</div>
        <div class="title" v-else-if="0.45 < item.CaseValue > 0.9" style="font-size:10px">注意</div>
        <div class="title" v-else-if="item.CaseValue > 0.9" style="font-size:10px">良好</div>
        <div class="content" :style="{ background: item.defaultBga }">
        </div>
      </div>
    </div>

    <!-- 器官功能使用的样式 -->
    <div v-if="type == 'organ'" class="organ">
      <div class="slide_item" v-for="(item) in slideItem17" :key="item.data" @click="routerPage(item)">
        <img class="img" :src="item.imgUrl" alt="" />
        <div class="title" :style="{ background: item.defaultBga }">
          {{ item.title }}
        </div>
        <div class="intr">{{ item.introduce }}</div>

        <div class="organs">
          <div>
            <img src="../../assets/yuan.png" alt="">
            <div class="ch">{{ item.data1 }}</div>
          </div>
          <div>
            <img src="../../assets/yuan.png" alt="">
            <div class="ch">{{ item.data2 }}</div>
          </div>
        </div>
        <div class="organes">
          <img class="img" src="../../assets/yuan.png" alt="">
          <div class="chin">{{ item.data3 }}</div>
        </div>
        <div class="ellipse">
          <img class="img img1" src="../../assets/ellipse.png" alt="" />
          <div class="know know1">了解详情</div>
        </div>

        <div>
        </div>
      </div>
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <img class="img" :src="item.imgUrl" alt="" />
        <div class="title" :style="{ background: item.defaultBga }">
          {{ item.title }}
        </div>
        <div class="intr">{{ item.introduce }}</div>
        <div class="ellipse ellipse1">
          <img class="img " src="../../assets/ellipse.png" alt="" />
          <div class="know">了解详情</div>
          <img class="yuan" src="../../assets/yuan.png" alt="">
          <div class="data">{{ item.data }}</div>
        </div>

      </div>
    </div>

    <!-- 标题在上 -->
    <div v-if="type == 'top'" class="top">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <img class="img" :src="item.imgUrl" alt="" />
        <div class="title">
          {{ item.title }}
        </div>
        <div class="intr">{{ item.introduce }}</div>
        <div class="ellipse">
          <img class="img" src="../../assets/ellipse.png" alt="" />
          <div class="know">了解详情</div>
          <img class="image" :src="item.imgurl" alt="" />
        </div>

      </div>
    </div>

    <!-- 左右布局 -->
    <div v-if="type == 'leftright'" class="leftright">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">

        <div class="right">
          <img class="image" :src="item.imgUrl" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="line"></div>
          <div class="subtitle">{{ item.subtitle }}</div>
          <div class="go">
            <img class="img" :src="url" alt="" />
          </div>
        </div>
        <!-- <div class="left" :style="{ background: item.defaultBga }">

        </div> -->
      </div>
    </div>

    <!-- 标题在下 -->
    <div v-if="type == 'titlebutBig'" class="titlebutBig">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <img class="img" :src="item.imgUrl" alt="" />
        <div>
          <img class="image" :src="item.img" alt="">
        </div>
        <div class="title" :style="{ background: item.defaultBga }">
          {{ item.title }}
        </div>
        <div class="go">
          <img class="img" :src="url" alt="" />
        </div>
        <div>

        </div>
      </div>
    </div>

    <!-- 左布局 -->
    <div v-if="type == 'onlyleft'" class="onlyleft">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <div class="left" :style="{
          backgroundImage: 'url(' + item.imgUrl + ')',
          color: item.color,
        }">
          <div class="title">{{ item.title }}</div>
          <div class="line"></div>
          <div class="subtitle">{{ item.subtitle }}</div>
          <div class="go">
            <img class="img" :src="url" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 普通icon -->
    <div v-if="type == 'healplan'" class="healplan">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <div class="title" :style="{ background: item.titleBga }">
          {{ item.title }}
        </div>
        <div class="content" :style="{ background: item.defaultBga }">
          <div class="con_img">
            <img class="img" :src="item.imgUrl" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 普通+img -->
    <div v-if="type == 'healmonth'" class="healmonth">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <div class="title" :style="{ background: item.textBga }">
          {{ item.title }}
        </div>
        <div class="content">
          <div class="con_img">
            <img class="img" :src="item.imgUrl" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 普通标题在下 -->
    <div v-if="type == 'emotion'" class="emotion">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <div class="content">
          <img class="img" :src="item.imgUrl" alt="" />
        </div>
        <div class="title">
          {{ item.title }}
        </div>
        <div class="go">
          <img class="img" :src="url" alt="" />
        </div>
      </div>
    </div>

    <!-- 普通标题在下 颜色渐变 -->
    <div v-if="type == 'emotionGra'" class="emotionGra">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <div class="content">
          <img class="img" :src="item.imgUrl" alt="" />
        </div>
        <div class="title" :style="{
          background:
            'linear-gradient(to top, ' + item.titleBga + ', transparent)',
          color: item.color,
        }">
          {{ item.title }}
        </div>
      </div>
    </div>

    <!-- 圆形 -->
    <div v-if="type == 'circular'" class="circular">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <div class="content">
          <img class="img" :src="item.imgUrl" alt="" />
        </div>
        <div class="title">{{ item.title }}</div>

      </div>
    </div>
    <!-- 普通标题在下 我的疗愈计划二级页面 -->
    <div v-if="type == 'emotionLang'" class="emotionLang">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <div class="content">
          <img class="img" :src="item.imgUrl" alt="" />
        </div>
        <div class="title" :style="{
          background:
            'linear-gradient(to top, ' + item.titleBga + ', transparent)',
          color: item.color,
        }">
          {{ item.title }}
        </div>
      </div>
    </div>

    <!-- 我的疗愈计划二级页面 自动疗愈 -->
    <div v-if="type == 'health'" class="health">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <img class="img" :src="item.imgUrl" alt="" />
        <div class="title">{{ item.title }}</div>
        <div class="text">
          <img class="alarm" src="../../assets/alarm.png" alt="">
          <div class="time">疗愈时间</div>
          <div>
            <div class="item">
              <div>
                <span class="num">2</span>
                <span class="hour">小时</span>
                <span class="num">30</span>
                <span class="hour">分钟</span>
              </div>
            </div>
            <div class="quantity">
              <div class="theme">
                <div>累计疗愈次数</div>
                <div class="add">20<span>/次</span></div>

              </div>
              <div>
                <div>累计疗愈时长</div>
                <div class="add">50<span>分钟</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 我的疗愈计划二级页面 穴位图片 -->
    <div v-if="type == 'picture'" class="picture">
      <div class="slide" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <img class="img" src="../../assets/body.png" alt="" />
      </div>
    </div>

    <div v-if="type == 'dietary'" class="dietary">
      <div class="slide_item" v-for="(item, index) in slideItem" :key="index" @click="routerPage(item)">
        <img class="img" :src="item.imgUrl" alt="" />
        <div class="title">
          {{ item.title }}
        </div>
        <div class="text">
          <div class="item">{{ item.soup }}</div>
          <div class="item">{{ item.drink }}</div>
          <div class="item">{{ item.fruit }}</div>
          <div class="item">{{ item.longan }}</div>
          <div class="item">{{ item.peach}}</div>
          <div class="food">{{ item.first }}</div>
          <div class="food">{{ item.second }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      url: `${this.$imgDomain}/www/HealthingCore/go2.png`,
      currentRate: 0,
      currentRate1: 0,
      currentRate2: 0,
      rate: 100
    }
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    // 函数形式
    slideItem: {
      arr: {
        type: Array,
        default: () => []
      }
    },
    slideItem17: {
      arr: {
        type: Array,
        default: () => []
      }
    }
  },
  created () { },
  mounted () { },
  methods: {
    routerPage (data) {
      if (data.route) {
        this.$router.push({ path: data.route })
      }
    }
  },
  watch: {},
  computed: {
    text () {
      return this.currentRate.toFixed(0) + '%'
    }
  }
}
</script>
<style lang="scss" scoped>
.slide_item{
  width:28% !important;
  height:130px !important;
  margin: 0 5px !important;
  font-size: 10px !important;
}
.abilityDetails .content .marg_left{
  width: 95% !important;
  margin-left: 10px !important;
}
.slide .ordinary .slide_item .quan{
  width: 60px !important;
  height: 60px !important;
}
.slide .ordinary .slide_item .disease{
  margin-top: -105px !important;
}
.slide .ordinary .slide_item .title{
  margin-top: 30px !important;
}
.slide {
  // overflow-x: scroll;

  .dietary,
  .picture,
  .health,
  .top,
  .organ,
  .ordin,
  .ordinary,
  .leftright,
  .titlebutBig,
  .onlyleft,
  .healplan,
  .healmonth,
  .emotion,
  .emotionGra,
  .emotionLang {
    display: flex;

    .slide_item {
      flex-shrink: 0;
      margin-right: 10px;
      height: 230px;
      border-radius: 15px;
      overflow: hidden;
    }
  }

  .ordinary {
    .slide_item {
      height: 200px;

      .quan {
        width: 120px;
        height: 120px;
        margin: -170px auto 50px;
      }

      .title {
        color: #898989;
        margin-top: 50px;
        text-align: center;
        font-size: 15px;
        color: #5a5a5a;
      }

      .disease {
        color: #898989;
        margin-top: -120px;
        text-align: center;
        font-size: 15px;
        color: #fff;
      }

      .content {
        font-size: 14px;
        color: #fff;
      }
    }
  }

  .ordin {
    .slide_item {
      height: 200px;

      .quan {
        width: 120px;
        height: 120px;
        margin: -170px auto 50px;
      }

      .title {
        color: #898989;
        margin-top: -50px;
        text-align: center;
        font-size: 15px;
        color: #5a5a5a;
      }

      .content {
        font-size: 14px;
        color: #fff;
      }
    }
  }

  .organ {

    .slide_item {
      position: relative;

      .img {
        width: 230px;
        height: 220px;
      }

      .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.2rem;
        color: #fefeff;
        opacity: 0.8;
      }

      .intr {
        margin: -160px 0 0 20px;
        font-size: 0.19rem;
        color: #9d9d9d
      }

      .ellipse {

        .img {
          width: 65px;
          height: 18px;
          margin: 5px 20px;
        }

        .img1 {
          margin-left: 135px;
          margin-top: -30px;
        }

        .know {
          color: #fff;
          margin: -23px 25px;
        }

        .know1 {
          margin-left: 140px;
        }

        .yuan {
          width: 75px;
          height: 75px;
          margin: 30px 0 0 80px;
        }

        .data {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 80px;
          font-size: 15px;
          margin-left: 78px;
          margin-top: -52px;
          text-align: center;
          color: #8b8b8b;
        }
      }

      .organs {
        display: flex;
        justify-content: space-evenly;

        img {
          width: 70px;
          height: 70px;
        }

        .ch {
          margin-top: -47px;
          text-align: center;
          font-size:17px;
          color:#a7a6ab
        }
      }

      .organes {
        display: flex;

        img {
          width: 70px;
          height: 70px;
          margin-top: 30px;
          margin-left: 30px;
        }

        .chin {
          margin-top: 52px;
          margin-left: -50px;
          color:#a7a6ab;
          font-size:17px;
        }
      }
    }
  }

  .top {
    .slide_item {
      position: relative;

      .img {
        width: 230px;
        height: 220px;
      }

      .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.2rem;
        color: #fefeff;
        opacity: 0.8;
      }

      .intr {
        margin: -160px 0 0 20px;
        font-size: 0.19rem;
        color: #9d9d9d
      }

      .ellipse {
        .img {
          width: 65px;
          height: 18px;
          margin: 5px 20px;
        }

        .know {
          color: #e3d5fe;
          margin: -23px 25px;
        }

        .image {
          width: 70px;
          height: 70px;
          margin: 30px auto;
        }
      }
    }
  }

  .leftright {
    color: #fff;

    .slide_item {
      display: flex;
      height: 150px;

      .right {
        .image {
          width: 280px;
          height: 150px;
        }

        .title {
          font-size: 0.26rem;
          margin-top: -120px;
          margin-left: 20px;
        }

        // .line {
        //   width: 37px;
        //   height: 10px;
        //   border:1px solid #999;
        //   border-radius: 3px;
        //   margin-top: 5px;
        //   margin-left: 48px;
        //   background:aqua;
        // }

        .subtitle {
          font-size: 0.14rem;
          margin-top: 10px;
          margin-left: 15px;
        }

        .go {
          width: 30px;
          height: 15px;
          border-radius: 15px;
          overflow: hidden;
          margin-top: 30px;
          margin-left: 45px;
        }
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;

      }
    }
  }

  .titlebutBig {
    .slide_item {
      position: relative;

      .img {
        width: 230px;
        height: 220px;
      }

      .image {
        width: 70px;
        height: 70px;
        margin: -150px auto;

      }

      .title {
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: 10px;
        left: 0;
        display: flex;
        align-items: center;
        font-size: 0.2rem;
        color: #fff;
        margin-left: 20px;
      }

      .go {
        .img {
          width: 30px;
          height: 15px;
          border-radius: 15px;
          margin-top: 203px;
          margin-left: 180px;
        }
      }
    }
  }

  .onlyleft {

    .slide_item {
      width: 280px;
      height: 157px;
      display: flex;

      .left {
        width: 100%;
        padding: 0 0 0 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat;

        .title {
          font-size: 0.26rem;
          color: #fff;
        }

        .line {
          width: 37px;
          height: 3px;
          background: #fff;
          border-radius: 3px;
          margin-top: 5px;
          margin-left: 20px;
        }

        .subtitle {
          font-size: 0.12rem;
          margin-top: 10px;
          color: #fff;
        }

        .go {
          width: 30px;
          height: 15px;
          border-radius: 15px;
          overflow: hidden;
          margin-top: 30px;
          margin-left: 30px;
        }
      }
    }
  }

  .healplan {
    .slide_item {
      width: 325px;

      .title {
        height: 0.5rem;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.22rem;
        color: #fff;
      }

      .content {
        height: calc(100% - 0.5rem);
        display: flex;
        align-items: center;
        justify-content: center;

        .con_img {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  .healmonth {
    .slide_item {
      width: 215px;
      height: 157px;

      .title {
        height: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.26rem;
        color: #fff;
        z-index: 99;
      }

      .content {
        height: calc(100% - 0.5rem);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        overflow: hidden;

        .con_img {
          width: 80%;
        }
      }
    }
  }

  .emotion,
  .emotionGra {
    .slide_item {
      width: 280px;
      height: 157px;
      position: relative;

      .content {
        height: 100%;
      }

      .title {
        margin-top: -130px;
        margin-left: -75px;
        width: 100%;
        height: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.24rem;
        color: #fff;
        text-overflow: ellipsis;
        white-space: wrap;
      }

      .go {
        width: 30px;
        height: 15px;
        border-radius: 15px;
        overflow: hidden;
        margin-top: 50px;
        margin-left: 45px;
      }
    }
  }

  .emotionGra {
    .slide_item {
      .title {
        opacity: 1;
      }
    }
  }

  .circular {
    display: flex;

    .slide_item {
      margin-right: 10px;

      .content {
        width: 157px;
        height: 154px;
        overflow: hidden;
        border-radius: 1rem;
      }

      .title {
        width: 100%;
        text-align: center;
        color: #898989;
        margin-top: 10px;
        font-size: 0.22rem;
      }
    }
  }

  .emotionLang {
    display: flex;

    .slide_item {
      width: 154px;
      height: 225px;
      position: relative;

      .content {
        height: 100%;
      }

      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.22rem;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .health {
    .slide_item {
      position: relative;

      .img {
        width: 330px;
        height: 220px;
      }

      .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.2rem;
        color: #fefeff;
        opacity: 0.8;
      }

      .text {
        .alarm {
          margin-top: -150px;
          margin-left: 90px;
        }

        .time {
          margin-top: -25px;
          margin-left: 130px;
        }

        .item {
          display: flex;
          margin-top: 5px;
          margin-left: 110px;

          .num {
            font-size: 30px;
            color: #ff9580
          }

          .hour {
            font-size: 18px;
          }
        }

        .quantity {
          display: flex;
          justify-content: space-evenly;
          margin-top: 10px;
          font-size: 18px;
          text-align: center;

          .add {
            color: #ff9580;
            font-size: 25px;
          }

          span {
            color: #8f8f8f;
            font-size: 15px;
          }

          .theme {
            width: 135px;
            border-right: 2px solid #d8d8d8;

          }
        }
      }
    }
  }

  .picture {
    .slide {
      position: relative;
      flex-shrink: 0;
      margin-right: 10px;
      border-radius: 15px;
      overflow: hidden;

      .img {
        width: 350px;
        height: 440px;
        background-color: #ffffff;
      }
    }
  }

  .dietary {
    .slide_item {
      position: relative;

      .img {
        width: 330px;
        height: 220px;
      }

      .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.2rem;
        color: #fefeff;
        opacity: 0.8;
      }

      .text {
        margin-top: -170px;
        font-size:15px;
        .item{
          color:'#464646';
          margin:10px 30px;
        }
        .food{
          width:300px;
          margin:10px;
        }
      }
    }
  }
}
</style>
