import Vue from 'vue'
const vm = new Vue()
const imgDomain = `${vm.$imgDomain}/www/HealthingCore/`

export default {
  slideItem: [
    {
      title: '需关注的领域',
      imgUrl: `${imgDomain}dailyattention.png`,
      introduce: '智能健康检测',
      imgurl: `${imgDomain}daily(1).png`,
      img: `${imgDomain}ellipse.png`

    },
    {
      title: '不能吃的食物',
      imgUrl: `${imgDomain}dailyattention.png`,
      introduce: '科学饮食 吃出健康',
      imgurl: `${imgDomain}daily(2).png`
    }

  ],
  slideItem2: [

    {
      title: '心率',
      imgUrl: `${imgDomain}dailyattention.png`,
      introduce: '心率检测 心电图解读',
      data: '132次/分钟',
      route: 'heartRate'

    },
    {
      title: '睡眠',
      imgUrl: `${imgDomain}dailyattention.png`,
      introduce: '舒眠减压 检测睡眠',
      data: '6小时',
      route: 'sleep'

    },
    {
      title: '血压',
      imgUrl: `${imgDomain}dailyattention.png`,
      introduce: '血压测量 血压分析',
      data: '173/123 mmHg',
      route: 'blood'
    },
    {
      title: '血氧饱和度',
      imgUrl: `${imgDomain}dailyattention.png`,
      introduce: '连续血氧测量',
      data: '98%',
      route: 'bloodOxygen'
    },
    {
      title: '压力',
      imgUrl: `${imgDomain}dailyattention.png`,
      introduce: '压力检测 减压放松',
      data: '27放松',
      route: 'pressure'
    },
    {
      title: '步数',
      imgUrl: `${imgDomain}dailyattention.png`,
      introduce: '今日步数 燃烧热量',
      data: '8067步数',
      route: 'step'
    }
  ],
  slideItem3: [
    {
      title: '我的经常疗愈',
      imgUrl: `${imgDomain}healingplan.png`,
      img: `${imgDomain}(1).png`
    },
    {
      title: '我的疗愈收藏',
      imgUrl: `${imgDomain}healingplan.png`,
      img: `${imgDomain}(2).png`
    },
    {
      title: '我的疗愈计划',
      imgUrl: `${imgDomain}healingplan.png`,
      img: `${imgDomain}(3).png`
    },
    {
      title: '打卡',
      imgUrl: `${imgDomain}healingplan.png`,
      img: `${imgDomain}(4).png`
    },
    {
      title: '书籍/小说',
      imgUrl: `${imgDomain}healingplan.png`,
      img: `${imgDomain}(5).png`
    },
    {
      title: '音频',
      imgUrl: `${imgDomain}healingplan.png`,
      img: `${imgDomain}(6).png`
    },
    {
      title: '其他',
      imgUrl: `${imgDomain}healingplan.png`,
      img: `${imgDomain}(7).png`
    }

  ],
  slideItem4: [
    {
      title: '平复当下情绪',
      subtitle: '别让情绪左右你',
      imgUrl: `${imgDomain}immediate1.png`,
      color: '#fff'
    },
    {
      title: '消除水肿',
      subtitle: '15分钟消肿更轻松',
      imgUrl: `${imgDomain}immediate2.png`,
      color: '#595757'
    },
    {
      title: '提升精力体力',
      subtitle: '压力与情绪性失眠',
      imgUrl: `${imgDomain}immediate3.png`,
      color: '#595757'
    },
    {
      title: '快速入眠',
      subtitle: '压力与情绪性失眠',
      imgUrl: `${imgDomain}immediate4.png`,
      color: '#595757'
    }
  ],
  slideItem5: [
    {
      title: '家庭关系',
      imgUrl: `${imgDomain}emotion(3).png`,
      titleBga: '#B47530',
      type: 10
    },
    {
      title: '竞争关系',
      imgUrl: `${imgDomain}emotion(4).png`,
      titleBga: '#77A6B5',
      type: 11
    },
    {
      title: '我的过敏源',
      imgUrl: `${imgDomain}emotion(2).png`,
      titleBga: '#667D98',
      type: 12
    },
    {
      title: '麻烦的关系建议',
      imgUrl: `${imgDomain}emotion(1).png`,
      titleBga: '#A59096',
      type: 13
    },
    {
      title: '情绪急救',
      imgUrl: `${imgDomain}emotion(5).png`,
      titleBga: '#A59096',
      type: 14
    }
  ],
  slideItem6: [
    {
      title: '睡眠调频所',
      subtitle: '疗愈压力与情绪性失眠',
      imgUrl: `${imgDomain}liaoyu1.png`
    },
    {
      title: '零压力专柜',
      subtitle: '细胞有害情绪精华',
      imgUrl: `${imgDomain}liaoyu2.png`
    },
    {
      title: '创伤和解庭',
      subtitle: '心理创伤修复疗愈',
      imgUrl: `${imgDomain}liaoyu3.png`
    },
    {
      title: '压力性脱发',
      subtitle: '别让脸抢了头发的地盘',
      imgUrl: `${imgDomain}liaoyu4.png`
    }
  ],
  slideItem33: [
    {
      title: '压力与情绪',
      defaultBga: '#948186',
      imgUrl: `${imgDomain}bg1.png`
    },
    {
      title: '免疫与自愈力',
      defaultBga: '#7298A6',
      imgUrl: `${imgDomain}bg2.png`
    },
    {
      title: '主题健康',
      defaultBga: '#C17F70',
      imgUrl: `${imgDomain}bg3.png`
    },
    {
      title: '个人能量',
      defaultBga: '#77A6B5',
      imgUrl: `${imgDomain}bg4.png`
    },
    {
      title: '公司能量',
      defaultBga: '#77A6B5',
      imgUrl: `${imgDomain}bg5.png`
    },
    {
      title: '生活空间',
      defaultBga: '#77A6B5',
      imgUrl: `${imgDomain}bg6.png`
    }
  ],
  slideItem66: [
    {
      title: '自动修复设定',
      imgUrl: `${imgDomain}plan1.png`,
      defaultBga: '#77A6B5',
      titleBga: '#588893'
    },
    {
      title: '主题疗愈对比报告',
      imgUrl: `${imgDomain}plan2.png`,
      defaultBga: '#B7B6A5',
      titleBga: '#9A9985'
    },
    {
      title: '主动加入疗愈计划',
      imgUrl: `${imgDomain}plan3.png`,
      defaultBga: '#C9B5Bc',
      titleBga: '#A59096'
    }
  ],
  slideItem7: [
    {
      title: '月度健康管理建议',
      imgUrl: `${imgDomain}monhealth1.png`,
      textBga: '#9966FF',
      route: ''
    },
    {
      title: '器官功能',
      imgUrl: `${imgDomain}monhealth3.png`,
      textBga: '#9966FF',
      route: ''
    },
    {
      title: '血压走势',
      imgUrl: `${imgDomain}monhealth4.png`,
      textBga: '#9966FF',
      route: 'bloodTrend'
    },
    {
      title: '心率走势',
      imgUrl: `${imgDomain}monhealth5.png`,
      textBga: '#9966FF',
      route: 'heartTrend'
    },
    {
      title: '血氧饱和度走势',
      imgUrl: `${imgDomain}monhealth6.png`,
      textBga: '#9966FF',
      route: 'bloodOxTrend'
    },
    {
      title: '压力走势',
      imgUrl: `${imgDomain}monhealth7.png`,
      textBga: '#9966FF',
      route: 'pressureTrend'
    },
    {
      title: '睡眠走势',
      imgUrl: `${imgDomain}monhealth8.png`,
      textBga: '#9966FF',
      route: 'sleepTrend'
    }
  ],

  slideItem9: [
    {
      title: '需要关注领域',
      defaultBga: '#FFE164'
    },
    {
      title: '',
      defaultBga: '#9966FF'
    },
    {
      title: '',
      defaultBga: '#00CCCC'
    },
    {
      title: '',
      defaultBga: '#FFE164'
    },
    {
      title: '',
      defaultBga: '#9966FF'
    }
  ],
  slideItem10: [
    {
      title: '改善过敏',
      imgUrl: `${imgDomain}baby1.jpg`,
      color: '#2E5187',
      titleBga: '#9DB8D3'
    },
    {
      title: '改善眼部疲劳',
      imgUrl: `${imgDomain}baby2.jpg`,
      color: '#FFF',
      titleBga: '#E79959'
    },
    {
      title: '提升免疫力',
      imgUrl: `${imgDomain}baby3.jpg`,
      color: '#3DA499',
      titleBga: '#CDFEF9'
    },
    {
      title: '摆脱成瘾',
      imgUrl: `${imgDomain}baby3.jpg`,
      color: '#2A3A79',
      titleBga: '#828DBA'
    }
  ],
  slideItem11: [
    {
      title: '感冒',
      imgUrl: `${imgDomain}baby4.jpg`
    },
    {
      title: '提升记忆力',
      imgUrl: `${imgDomain}baby5.png`
    },
    {
      title: '提升精力体力',
      imgUrl: `${imgDomain}baby6.jpg`
    },
    {
      title: '改善厌食',
      imgUrl: `${imgDomain}baby7.jpg`
    }
  ],
  slideItem12: [
    {
      title: '宝贝个人能量',
      imgUrl: `${imgDomain}baby8.png`
    },
    {
      title: '宝贝学习能量',
      imgUrl: `${imgDomain}baby9.png`
    }
  ],
  slideItem13: [
    {
      title: '家庭关系',
      imgUrl: `${imgDomain}relation1.jpg`,
      color: '#A22017',
      titleBga: '#FDACA8'
    },
    {
      title: '竞争关系',
      imgUrl: `${imgDomain}relation2.jpg`,
      color: '#A66620',
      titleBga: '#FFD79A'
    },
    {
      title: '现有关系的错误认知',
      imgUrl: `${imgDomain}relation3.jpg`,
      color: '#343153',
      titleBga: '#E4EBF0'
    },
    {
      title: '我的过敏源',
      imgUrl: `${imgDomain}relation4.jpg`,
      color: '#B35730',
      titleBga: '#E0CDB9'
    }
  ],
  slideItem14: [
    {
      title: '健康警报',
      defaultBga: '#F8A05D'
    },
    {
      title: '主要情绪',
      defaultBga: '#FF9280'
    },
    {
      title: '功能状态',
      defaultBga: '#BE7F70'
    },
    {
      title: '智能穿戴建议',
      defaultBga: '#F7B435'
    },
    {
      title: '今日最差器官',
      defaultBga: '#BB7E4D'
    },
    {
      title: '不能吃的食物',
      defaultBga: '#BA4C4F'
    }
  ],
  slideItem15: [
    {
      title: '月度健康管理建议',
      imgUrl: `${imgDomain}report1.png`,
      textBga: '#F8A05D'
    },
    {
      title: '压力走势',
      imgUrl: `${imgDomain}report2.png`,
      textBga: '#F8A05D'
    },
    {
      title: '器官功能',
      imgUrl: `${imgDomain}report3.png`,
      textBga: '#F8A05D'
    },
    {
      title: '健康风险',
      imgUrl: `${imgDomain}report4.png`,
      textBga: '#F8A05D'
    },
    {
      title: '健康警报',
      imgUrl: `${imgDomain}report5.png`,
      textBga: '#F8A05D'
    }
  ],
  slideItem17: [
    {

      title: '器官功能',
      imgUrl: `${imgDomain}dailyattention.png`,
      data1: '胃',
      data2: '胰腺',
      data3: '肝脏'
    }
  ],
  slideItem18: [
    {
      title: '已服用两年',
      disease: '高血压'
    },
    {
      title: '已服用两年',
      disease: '糖尿病'
    },
    {
      title: '已服用两年',
      disease: '肝炎'
    },
    {
      title: '已服用两年',
      disease: '脂肪肝'
    }
  ],
  slideItem19: [
    {
      title: '性腺',
      disease: '脂肪肝'
    },
    {
      title: '免疫系统',
      disease: '脂肪肝'
    },
    {
      title: '睡眠',
      disease: '脂肪肝'
    },

  ],
  slideItem20: [
    {
      title: '自动疗愈',
      imgUrl: `${imgDomain}dailyattention.png`,
      introduce: '智能健康检测'

    },
    {
      title: '主动疗愈',
      imgUrl: `${imgDomain}dailyattention.png`,
      introduce: '智能健康检测'
    }
  ],
  slideItem21: [
    {
      imgUrl: `${imgDomain}body.png`

    },
    {
      imgUrl: `${imgDomain}body.png`
    }, {
      imgUrl: `${imgDomain}body.png`
    }
  ],
  slideItem22: [
    {
      imgUrl: `${imgDomain}dailyattention.png`,
      title: '食疗汤方',
      soup: '1、海带姜汤',
      drink: '2、有机卷心菜汁水'
    },
    {
      imgUrl: `${imgDomain}dailyattention.png`,
      title: '不能吃',
      fruit: '1、荔枝',
      longan: '2、龙眼',
      peach: '3、水蜜桃'
    },
    {
      title: '饮食原则',
      imgUrl: `${imgDomain}dailyattention.png`,
      first: '1、建议三餐定时、适量,切勿宝音暴食;',
      second: '2、多摄取6种矿物质:锌、硒、铬、铜、镁、钾,可以从糖蜜与粗盐(粗盐必须少量摄取)中提取到。'

    }
  ]
}
